import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addUserClass,
  deleteUserClass,
  eventStartLoading,
  getClasesDeletePendingUser,
  getClasesPendingUser,
} from "../../actions/events";
import { getInitialNameUser } from "../../helpers/getInitialNameUser";

export const Clase = (event) => {
  const { color, clase } = event;

  const { bg, icon } = color;

  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.auth);
  const { totales } = useSelector((state) => state.clases);
  const { events } = useSelector((state) => state.calendar);
  const usuario = clase.users.filter((user) => user._id === uid);
  const bonoActual = useSelector((state) => state.bonosmes.bonoActual);

  const unlimitedBono = bonoActual.bono.includes("67459bddec95d900153d4e0d");

  const inClass = events.filter((event) =>
    event.users.find((user) => user._id === uid),
  );
  const isUserInCLass = events.map((elemnt) =>
    elemnt?.users.map((el) => el._id.includes(uid)),
  );

  const timeCloseClass = () => {
    const now = moment();

    if (usuario.length === 0) {
      const classClose = moment(clase.start).add(-5, "minutes");
      return now >= classClose;
    }

    const classClose = moment(clase.start).add(-45, "minutes");
    return now >= classClose;
  };

  const handleReserva = () => {
    dispatch(addUserClass(clase));
    dispatch(getClasesPendingUser());
  };

  useEffect(() => {
    dispatch(eventStartLoading());
  }, [totales, dispatch]);

  const handleDelete = () => {
    dispatch(deleteUserClass(clase));
    dispatch(getClasesDeletePendingUser());
  };

  const showButton = () => {
    if (totales === 0 && !usuario.length) {
      return (
        <button className="bg-yellow-500 font-bold py-2 text-blue-500 float-left focus:ring-2 focus:none uppercase cursor-not-allowed">
          renueva tu bono
        </button>
      );
    }

    if (usuario.length || (usuario.length && unlimitedBono)) {
      return (
        <button className="bg-green-500 py-2 text-white float-left font-bold focus:ring-2 focus:none uppercase cursor-not-allowed">
          Registrado
        </button>
      );
    }

    if (
      (isUserInCLass && inClass.length === 1 && !unlimitedBono) ||
      (isUserInCLass && inClass.length === 2 && unlimitedBono)
    ) {
      return (
        <button className="bg-blue-300 py-2 text-white float-left font-bold focus:ring-2 focus:none uppercase cursor-not-allowed">
          ya esta registrado
        </button>
      );
    }

    if (clase.userClase === clase.users.length) {
      return (
        <button
          title="Debes de esperar a que algún usuario deje su plaza"
          className="bg-red-400 py-2 text-white float-left font-bold focus:ring-2 focus:none uppercase cursor-not-allowed"
        >
          Completa
        </button>
      );
    }

    return (
      <button
        onClick={handleReserva}
        className="bg-blue-900 py-2 text-blue-100 float-left focus:ring-2 focus:none font-bold uppercase"
      >
        {" "}
        Regístrate
      </button>
    );
  };

  const showButtonleft = () => {
    if (clase.userclase === clase.users.length && !usuario.length) {
      return (
        <button
          className="bg-red-400 py-2 text-blue-100 font-bold float-right uppercase cursor-not-allowed"
          disabled
        >
          {" "}
          Completa
        </button>
      );
    }

    if (totales === 0 && !usuario.length) {
      return (
        <button className="bg-yellow-500 font-bold py-2 text-blue-500 float-left focus:ring-2 focus:none uppercase cursor-not-allowed">
          renueva tu bono
        </button>
      );
    }

    if (!isUserInCLass && inClass.length === 1) {
      return (
        <button className="bg-blue-300 py-2 text-blue-100 float-right uppercase cursor-not-allowed font-bold">
          {" "}
          Ya esta registrado
        </button>
      );
    }

    return (
      <button
        onClick={handleDelete}
        className="bg-red-600 py-2 text-blue-100 font-bold float-right uppercase"
      >
        {" "}
        Dejar hueco
      </button>
    );
  };
  return (
    <>
      <div className={`clase rounded h-80 relative bg-blue-700 `}>
        <div className="clase__usuarios block h-8 shadow-2xl">
          <h3
            className="text-center px-6 text-red-500 font-bold"
            title="Máximos usuarios por clase"
          >
            <span
              title="Numero máximo de alumnos por clase"
              className={`absolute top-0 left-0 clase__usuarios-maximos px-5 bg-blue-900 text-4xl`}
            >
              {clase.userClase}
              <span className="">🏃‍♂️</span>
              <span className="text-sm block -mt-4">max</span>
            </span>
          </h3>
          <h3
            className={`text-center rounded-full px-6 text-green-500 font-bold`}
            title="Usuarios para esta clase"
          >
            <span
              title="Usuarios "
              className={`absolute top-0 right-0 clase__usuarios-count bg-blue-900 px-5 text-4xl`}
            >
              {clase.users.length > 0 ? clase.users.length : 0}🏃🏻‍♀️
              <span className="text-sm block -mt-4">cls</span>
            </span>
          </h3>
        </div>

        <div className={`mx-auto w-full text-blue-100 pt-10`}>
          <span className="text-6xl text-center grid">{icon}</span>
          <h1
            className={`title uppercase text-center text-3xl font-bold tracking-wide ${bg}`}
          >
            {clase.type}
            <p className="title uppercase text-center text-sm mb-3 font-bold -mt-3">
              {moment(clase.start).format("LL")}
            </p>
            <p className="bg-blue-700 py-2 hours uppercase text-3xl font-bold text-white -mt-3">
              {moment(clase.start).format("LT")} ⏱{" "}
              {moment(clase.end).format("LT")}
            </p>
          </h1>
        </div>

        {clase.users.length > 0 ? (
          <>
            <div className="clase__usuarios-registrados bg-blue-700 shadow-2xl grid grid-cols-6 sm:grid-cols-8 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-10">
              {clase.users.map((user) => (
                <div
                  title={user.name}
                  className="text-center flex flex-col justify-center gap-y-2 items-center w-full text-xs"
                  key={user._id}
                >
                  <p className="object-cover flex items-center justify-center rounded-full px-1 cursor-pointer text-1xl bg-yellow-600 text-blue-900 font-bold">
                    {" "}
                    {getInitialNameUser(user.name)}{" "}
                  </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="flex items-center clase__usuarios-registrados text-blue-100 py-3 px-3">
            <span className="flex items-center uppercase h-10 font-bold">
              Consigue la pole!! 🤪
            </span>
          </div>
        )}

        <div className="clase_buttons grid w-full grid-cols-2 sm:grid-cols-2 gap-6 md:grid-cols-2">
          {timeCloseClass() ? (
            <button className="bg-red-600 py-2 font-bold text-blue-100 float-right uppercase cursor-not-allowed">
              {" "}
              Clase cerrada
            </button>
          ) : (
            showButtonleft()
          )}

          {timeCloseClass() ? (
            <button className="bg-red-600 py-2 font text-blue-100 float-left focus:ring-2 focus:none font-bold uppercase cursor-not-allowed">
              {" "}
              Clase cerrada
            </button>
          ) : (
            showButton()
          )}
        </div>
      </div>
    </>
  );
};
